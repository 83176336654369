import { useEffect, useState } from "react";
import axios from "axios";
import { getShopifyStoreByCountry } from "../../../locale-shopifies";
import { useALError } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import { useCurrentCountryCode } from "../../hooks/usePrices";
import { isBrowser } from "../../context/helpers";
import { ALLoading } from "../../components/ALComponents";
import Breadcrumb from "../../components/Breadcrumb";
import SEO from "../../components/seo";
import "./policies.scss";

function TermsOfService() {
  const countryCode = useCurrentCountryCode();
  const { name } = getShopifyStoreByCountry(countryCode);
  const { sendReport } = useALError();

  const [policyData, updatePolicyData] = useState(null);
  const [policyDataError, updatePolicyDataError] = useState(false);

  useEffect(() => {
    if (name && isBrowser) {
      const _path = window?.location?.pathname?.replace("/policies/", "");
      axios({
        method: "get",
        url: `/.netlify/functions/policies?type=${_path}&store=${name}`,
      })
        .then((r) => {
          if (r.status === 200) {
            updatePolicyData(r.data);
            updatePolicyDataError(false);
          }
        })
        .catch((error) => {
          updatePolicyData(null);
          updatePolicyDataError(true);
          sendReport(error, { name: "TermsOfService", priority: "P5" });
        });
    }
  }, [name]);
  return policyData && !policyDataError ? (
    <div className="policies_container">
      <SEO title={policyData?.policy?.title} />
      <Breadcrumb pageTitle={policyData?.policy?.title} />
      <h1 className="policies_container_title al_h1">{policyData?.policy?.title}</h1>
      <div
        className="policies_container_content"
        dangerouslySetInnerHTML={{ __html: policyData?.policy?.body }}
      />
    </div>
  ) : policyDataError ? (
    <div>
      <p>Error loading...</p>
    </div>
  ) : (
    <div className="policies_loading">
      <ALLoading className="policies_loading_loader" />
      <p className="al_p">Loading policies...</p>
    </div>
  );
}

export default TermsOfService;
